export const ensureHttpProtocol = (url) => {
    try {
        if (!url) return url;
        const parsedUrl = new URL(url);
        return parsedUrl.href;
    } catch (e) {
        return `https://${url}`;
    }
};

export const removeExtraSlashes = (url) => {
    // Check if the URL starts with http:// or https://
    const protocolPattern = /^(https?:\/\/)/;
    const match = url.match(protocolPattern);
    
    if (match) {
        // Extract the protocol (http:// or https://)
        const protocol = match[0];
        // Remove the protocol from the URL
        const restOfUrl = url.slice(protocol.length);
        // Remove all slashes from the rest of the URL
        const cleanedUrl = restOfUrl.replace(/\//g, '');
        // Reattach the protocol
        return protocol + cleanedUrl;
    } else {
        // If no protocol, just remove all slashes
        return url.replace(/\//g, '');
    }
};

export const extractTextOrUrl= (str) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(str, 'text/html');
    const elements = doc.body.childNodes;
    const data = [];
    const urlRegex = /(https?:\/\/)?[\w.-]+\.[a-zA-Z]{2,}(\/\S*)?/;

    const findUrlsInNode = (node) => {
        if (node.nodeType === Node.TEXT_NODE) {
            const pUrl = node.textContent.match(urlRegex);
            if (pUrl) {
                const url = ensureHttpProtocol(pUrl[0]);
                return `<a target="_blank" href="${url}" rel="noopener noreferrer" style="color:#0d6efd">${url}</a>`;
            }
            return node.textContent;
        } else if (node.nodeName === 'A') {
            let url = node.href.replace(`${node.baseURI}/`, '');
            node.baseURI.split('/').forEach(urlChunk => {
                url = url.replace(urlChunk, '');
            });
            url = removeExtraSlashes(url);
            url = ensureHttpProtocol(url);
            return `<a target="_blank" href="${url}" rel="noopener noreferrer" style="color:#0d6efd">${node.textContent}</a>`;
        }
        return Array.from(node.childNodes).map(findUrlsInNode).join('');
    };

    for (let i = 0; i < elements.length; i++) {
        const el = elements[i];
        let content = el.outerHTML;

        if (el.nodeName !== 'OL' && el.nodeName !== 'UL') {
            content = findUrlsInNode(el);
        }

        data.push(content);
    }
    
    return data;
};
