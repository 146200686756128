import { createContext, useState, useEffect, useRef, useCallback } from 'react';
import { useFormContext } from "react-hook-form";
import { useParams } from 'react-router-dom';
import { QUESTION_LINK_TYPE } from "@ais/constants"
import { isAnswerQuestionGroup, isAnswerTable, transformAnswers, transformAnswersToPayload } from '@ais/utilities';

import { useFinalizedProject } from '@hooks/useProject';

import { useSaveProjectUnitAnswer } from '@services/forms/projectforms.js';
import projectFormServices, { useGetQuestionsWithLink } from "@services/forms/projectforms";
import { useProjectTrialBalances, useTrialBalances } from "@services/trialbalance";

import {
    FilterValidTrialBalances,
    IsAnswerTrialBalance,
    DynamicallyUpdateAnswerPayload
} from "@ais/forms/src/V2/FieldComponents/TbLink/TbLinkHelper";

import { CustomToast } from '@ais/components';
import logger from '@utilities/logService'
import { getFormattedSignOffDate } from '@utilities/dateHelpers';

export const ProjectFormInstanceContextV2 = createContext({});

export const ProjectFormInstanceProviderV2 = ({ projectData, projectFormData, answers, userId, children }) => {
    const [project] = useState(projectData);
    const [projectForm] = useState(projectFormData);
    const isProjectFinalized = useFinalizedProject(project.ProjectId);
    const [schema, setSchema] = useState(projectForm.schema);
    const [answerList, setAnswerList] = useState(transformAnswers(answers ?? []));
    const [answerArray, setAnswerArray] = useState(answers);
    const [isCustomFormError, setIsCustomFormError] = useState(false);
    const [isCustomFormSuccess, setIsCustomFormSuccess] = useState(false);
    const [isCustomFormLoading, setIsCustomFormLoading] = useState(false);
    const [isQuestionGroupIdle, setIsQuestionGroupIdle] = useState(false);
    const [linkDefaultFields, setLinkDefaultFields] = useState([])
    const [isSectionExpanded, setIsSectionExpanded] = useState([]);
    const [relatedFields, setRelatedFields] = useState([]);
    const [focusedId, setFocusedId] = useState(null)
    const [sectionOnEditView, setSectionOnEditView] = useState(null)
    const [deleteModalData, setDeleteModalData] = useState({visible: false, fieldId: null})
    const [showConflictResolveLink, setShowConflictResolveLink] = useState(false);
    const [hasSelectedDominantUnit, setHasSelectedDominantUnit] = useState(false);
    const { getValues, reset, setValue } = useFormContext();
    const { units } = projectForm;
    const { projectId, projectFormId } = useParams();
    const { data: questionsWithLink } = useGetQuestionsWithLink(projectId, projectFormId)
    const { mutateAsync: saveProjectUnitAnswer, isLoading, isError, isSuccess } = useSaveProjectUnitAnswer();
    const fieldRef = useRef(null);
    const { QUESTION_LINK_TYPE: {
        DUPLICATE_QUESTION,
        LINK_ANSWER,
        LINK_ANSWER_DEFAULT
    } } = QUESTION_LINK_TYPE;

    //TB Related Data
    const formHasTbLink = Object.values(answerList).some(value => IsAnswerTrialBalance(value));
    const { data: balances } = useProjectTrialBalances(project?.AppDataInstanceId, project?.ProjectId);
    const [trialBalanceOptionIds, setTrialBalanceOptionIds] = useState([]);//Available TBs which get queried in call below
    const trialBalanceRecordsQuery = useTrialBalances(trialBalanceOptionIds, project?.ProjectId);
    const [detailedTrialBalancesRetrieved, setDetailedTrialBalancesRetrieved] = useState(false);
    const [detailedTrialBalances, setDetailedTrialBalances] = useState([]);

    const dynamicallyUpdateTbLinkAnswers = () => {
        const filteredAnswerList = [];
        Object.entries(answerList).forEach(([questionId, answer]) => {
            if (IsAnswerTrialBalance(answer)) {
                filteredAnswerList.push({ questionId, answer });
            }
        });
        const updatedAnswerList = DynamicallyUpdateAnswerPayload(filteredAnswerList, detailedTrialBalances).filter(answer => answer.updated);
        updatedAnswerList.forEach(answer => {
            setValue(answer.questionId, answer.answer);
            submitPayload(answer.questionId, answer.answer);
        });
    }

    useEffect(() => {
        if (!balances) return;
        if (!formHasTbLink) return; // Do not query each TB for data if no TBs are linked in the form
        if (isProjectFinalized) return; // Do not query TB for data if project is finalized
        const filteredBalances = FilterValidTrialBalances(balances);
        /*Important note, many tests have invalid ingestions (missing correlationName for example)
        This only allows users to select valid ingestions, but v1 behavior also drives off invalid ingestions.
        For example, if user has a fund tb ingested that was invalid due to missing correlation name, the UI in V1 will still display the fund options available
        DESPITE the fact that the TB is invalid and not even displayed for selection on drop down. This is a bug in V1, which will not be carried over to V2.
        If we want to introduce this bug as a feature, simply swap the commented out line below with the line above it.
        See Kevin's home alone enterprises 2023_2023 project for an example of this behavior.
        */
        setTrialBalanceOptionIds(filteredBalances.map((balance) => balance.id));
        setDetailedTrialBalancesRetrieved(false);
    }, [balances]);

    useEffect(() => {
        if ((trialBalanceRecordsQuery.length > 0 && trialBalanceRecordsQuery.every((tbQuery) => tbQuery.isFetching === false && tbQuery.isFetched === true) && !detailedTrialBalancesRetrieved)) {
            setDetailedTrialBalancesRetrieved(true);
            //The process below basically lines up the detailedTrialBalance data response to incorporate the correlationNameId (available on the list of TBs response, but not on individual tb response)
            //This is crucial in order to support V1 behavior (you have 5 ingestions for 1 TB, but see 1 TB in dropdown, but other selections you make determine which of the 5 you really wanted...)
            const detailedTrialBalanceData = trialBalanceRecordsQuery.map((tbQuery) => tbQuery.data);
            const balancesMap = new Map();
            balances.forEach(balance => {
                balancesMap.set(balance.id, balance.correlationNameId);
            });
            const updatedTrialBalances = detailedTrialBalanceData.map(tb => {
                if (balancesMap.has(tb.id)) {
                    return { ...tb, correlationNameId: balancesMap.get(tb.id) };
                }
                return tb;
            });
            setDetailedTrialBalances(updatedTrialBalances);
        }
    }, [trialBalanceRecordsQuery]);

    useEffect(() => {
        if (detailedTrialBalances.length > 0) {
            dynamicallyUpdateTbLinkAnswers();
        }
    }, [detailedTrialBalances]);
    //End TB Related Data

    const updateQuestionsWithLink = (id, answer) => {
        const { schema } = projectForm

        const result = []

        schema.forEach(section =>
            section.fields.forEach(row =>
                row.forEach((field) => {
                    if (field.existingQuestionId && field.id === id && field.linkType === LINK_ANSWER) {
                        setValue(field.existingQuestionId, answer)
                        submitPayload(field.existingQuestionId, answer)
                        return
                    } else if (field.existingQuestionId === id) {
                        setValue(field.id, answer)
                        submitPayload(field.id, answer)
                        return
                    }
                })
            )
        );

        schema.forEach(section =>
            section.fields.forEach(row =>
                row.forEach((field) => {
                    if (field.existingQuestionId && field.id === id && field.linkType === LINK_ANSWER_DEFAULT) setLinkDefaultFields(prev => [...prev, id])
                    if (field.existingQuestionId && field.existingQuestionId === id && field.linkType !== DUPLICATE_QUESTION && !field.isUserModified) result.push(field.id)
                    if (field.existingQuestionId && field.id === id && field.linkType === LINK_ANSWER) result.push(field.existingQuestionId)
                })
            )
        );
        if (result.length > 0) {
            result.forEach(id => {
                if (!linkDefaultFields.includes(id)) {
                    setValue(id, answer)
                    setAnswerList(prev => {
                        return { ...prev, [id]: answer }
                    })
                }
            })
        }
    }

    const handleSubmit = async (questionId) => {
        const isQuestionGroup = isAnswerQuestionGroup(questionId);
        if (isQuestionGroup) questionId = questionId.split('.')[0]
        const answer = getValues(questionId);
        const isTable = isAnswerTable(answer);
        if (answer === answerList[questionId]) return;

        const transformedValue = transformAnswersToPayload(answer, isTable, isQuestionGroup);
        if (isQuestionGroup || isTable)
            if (JSON.stringify(answer.map(ans => {
                const obj = {}
                for (const key in ans) {
                    if (ans[key] !== null && ans[key] !== undefined) obj[key] = ans[key]
                }
                return obj
            })) === JSON.stringify(answerList[questionId])) return
        try {
            submitPayload(questionId, transformedValue);

            updateQuestionsWithLink(questionId, answer)

            setAnswerList(prev => {
                return { ...prev, [questionId]: answer }
            })
        } catch (error) {
            logger.error(error.response.data.message ?? error.message);
            //Toast handles seperately via property state awareness of mutation Object
        }
    }
    const submitPayload = useCallback(async (questionId, answer) => {
        const unitAnswer = {
            projectId,
            projectUnitIds: units.map(item => item.ProjectUnitID),
            questionId,
            answer
        };
        await saveProjectUnitAnswer({
            projectId,
            projectFormId,
            unitAnswer
        }).finally(()=>hasConflictAnswers());;  
        setAnswerArray((prevAnswer) => {
            return prevAnswer.map(_answer => {   
                if(_answer.projectId === parseInt(projectId) && _answer.questionId === questionId.toUpperCase()){
                    return {
                        ..._answer,
                        answer: answer,
                        lastUpdate: getFormattedSignOffDate(new Date().toISOString()),
                    }
                }
                return _answer;
            }) 
        })
    }, [answerArray])
    const handleFocusField = (e, id) => {
        if (e) fieldRef.current = e.target;
        setFocusedId(id);
    }

    useEffect(() => {
        if (Object.keys(answerList).length > 0) {
            reset(answerList);
        }
    }, [answerList])

    useEffect(() => {
        setAnswerList(transformAnswers(answers ?? []));
        setAnswerArray(answers)
        // link answer default if not modifed retrieve default or parent field
        projectForm.schema.forEach(section =>
            section.fields.forEach(row =>
                row.forEach((field) => {
                    if (field.linkType === LINK_ANSWER_DEFAULT && !field.isUserModified) {
                        const defaultAnswer = answers?.find(ans => ans.questionId?.toLowerCase() === field.existingQuestionId?.toLowerCase())
                        if (defaultAnswer) {
                            setValue(field.id, defaultAnswer.answer)
                            setAnswerList(prev => {
                                return { ...prev, [field.id]: defaultAnswer.answer }
                            })
                        }
                    }
                })
            )
        );
    }, [answers]);
    

    useEffect(() => {
        //TODO : Select dominant unit for Modal
        const hasDominantUnit = units?.some((unit) => !!unit.IsDominantUnit);
        if(units?.length === 1) {
            setShowConflictResolveLink(false);
        } 
        else if (units?.length > 1 && hasDominantUnit) {
            // if there s selected dominant unit set on conflict reso modal
            const selectedUnit = units?.find(unit => unit.IsDominantUnit);
            
            setShowConflictResolveLink(true);
            setHasSelectedDominantUnit(true);
        } else {
            hasConflictAnswers();
        }
    },[units])
    
    const hasConflictAnswers = async () => {
        const data = await projectFormServices.hasConflictAnswers(projectFormId, projectId)
        if (data?.HasConflicts) {
            setShowConflictResolveLink(true);
            return;
        }

        setShowConflictResolveLink(false);
    }

    const handleLinkClick = async (questionId) => {
        if (questionId) {
            const { data } = await projectFormServices.getRelatedFieldsByQuestionsIds(projectId, questionId)
            if (data) {
                const relatedFields = data.map(item => ({
                    formId: item.projectFormId,
                    formName: item.projectFormName,
                    questionIds: item.questionIds,
                    sectionId: item.sectionId,
                    sectionName: item.sectionName,
                }))
                setRelatedFields(relatedFields)
            }
        } else {
            setRelatedFields([])
        }
    }

    const handleCustomFormSavingState = (type, value) => {
        if (type === 'isLoading') setIsCustomFormLoading(value);
        if (type === 'isSuccess') setIsCustomFormSuccess(value);
        if (type === 'isError') setIsCustomFormError(value);
    };

    const handleIsSectionExpanded = (section, isExpanded) => {
        setIsSectionExpanded(prevState => {
            const i = prevState.findIndex(item => item.section === section)
            if (i !== -1) {
                return [
                    ...prevState.slice(0, i),
                    { ...prevState[i], isExpanded },
                    ...prevState.slice(i + 1)
                ]
            }
            else return [...prevState, { section, isExpanded }]
        })
    }
    return (
        <ProjectFormInstanceContextV2.Provider
            value={{
                project,
                projectForm,
                isProjectFinalized,
                answers,
                answerList,
                balances,
                detailedTrialBalances,
                questionsWithLink,
                relatedFields,
                focusedId,
                fieldRef,
                userId,
                schema,
                units,
                isSectionExpanded,
                isQuestionGroupIdle,
                sectionOnEditView,
                deleteModalData,
                answerArray,
                showConflictResolveLink,
                actions: {
                    onSubmit: handleSubmit,
                    onLinkClick: handleLinkClick,
                    onFocus: handleFocusField,
                    onCustomFormSaving: handleCustomFormSavingState,
                    onUpdateEvent: setAnswerList,
                    updateSchema: setSchema,
                    handleIsSectionExpanded,
                    updateQuestionGroupIdle: setIsQuestionGroupIdle,
                    handleSectionOnEditView: setSectionOnEditView,
                    handleDeleteModalData: setDeleteModalData,
                    updateDetailedTrialBalances: setDetailedTrialBalances
                }
            }}
        >
            {children}
            <CustomToast
                error={isError || isCustomFormError}
                success={isSuccess || isCustomFormSuccess}
                loading={isLoading || isCustomFormLoading}
            />
        </ProjectFormInstanceContextV2.Provider>
    )
}
